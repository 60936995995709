APP.home = function () {
	const images = []
	const sequence = $('.sequence')
	const kixx = { curImg: 0 }
	const tween = gsap.timeline()

	function init() {
		if ($('.intro').length === 0) return

		$('.slick-tiktok').slick({
			slidesToShow: 3,
			slidesToScroll: 3,
			infinite: true,
			arrows: false,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		})

		$('.slick-item').on('click', function () {
			const _this = $(this)
			const tiktokId = _this.data('tiktok-id')
			if (tiktokId) {
				$('.loader').show()
				fetch(
					`https://www.tiktok.com/oembed?url=https://www.tiktok.com/@kixxvietnam/video/${tiktokId}`
				)
					.then((response) => response.json())
					.then((data) => {
						const { html } = data
						$('.modal-embed').html(html)
						setTimeout(() => {
							$('.loader').hide()
							$('.modal-tiktok').show()
						}, 500)
					})
					.catch(() => {
						$('.loader').hide()
					})
			}
		})

		$('.intro-sequence-item').each(function () {
			const _this = $(this)
			const src = _this.attr('src')
			images.push(src)
		})

		$('.modal-close').on('click', function () {
			gsap.to('.modal-tiktok', {
				duration: 0.4,
				alpha: 0,
				y: 100,
				onComplete: function () {
					$('.modal-tiktok').removeAttr('style')
					setTimeout(() => {
						$('.modal-embed').html('')
					}, 150)
				},
			})
		})

		tween
			.to('.intro-video', {
				duration: 1,
				alpha: 1,
				ease: 'none',
			})
			.to(
				kixx,
				{
					duration: 1,
					curImg: images.length - 1,
					roundProps: 'curImg',
					repeat: 0,
					immediateRender: true,
					ease: 'none',
					onUpdate: function () {
						sequence.attr('src', images[kixx.curImg])
					},
				},
				'-=0.8'
			)
			.to('.arrow-down', {
				alpha: 0,
			})
			.to(
				'.intro-video',
				{
					duration: 1,
					alpha: 0,
					ease: 'none',
				},
				'-=0.3'
			)
			.fromTo(
				'.intro-kv-main',
				{ duration: 1, alpha: 0, scale: 0, ease: 'power1.out' },
				{ duration: 1, alpha: 1, scale: 1, ease: 'power1.out' },
				'-=1.1'
			)
			.fromTo(
				'.intro-kv-product img',
				{ duration: 0.7, x: '-100%', ease: 'power1.out' },
				{ duration: 0.7, x: '0%', ease: 'power1.out' }
			)
			.set('.intro-kv-text', { perspective: 180 })
			.set('.intro-kv-text img', {
				rotationX: -10,
				rotationY: -10,
				rotationZ: -10,
				ease: 'power1.out',
			})
			.fromTo(
				'.intro-kv-text',
				{ duration: 1, autoAlpha: 0, scale: 0.3, width: '30%', ease: 'power1.out' },
				{ duration: 1, autoAlpha: 1, scale: 1, ease: 'power1.out' }
			)
			.to('.intro-kv-text', {
				duration: 1,
				width: '100%',
				left: '0%',
				top: '50%',
				y: '-50%',
				perspective: 1500,
				ease: 'power1.out',
			})
			.fromTo(
				'.intro-kv-bg',
				{ alpha: 0, ease: 'power1.out' },
				{ alpha: 1, ease: 'power1.out' },
				'-=1.3'
			)
			.to('.intro-kv-text img', {
				duration: 1,
				rotationX: 0,
				rotationY: 0,
				rotationZ: 0,
				delay: -0.8,
				ease: 'power1.out',
			})
			.fromTo(
				'.intro-kv-text',
				{ duration: 1, rotationX: 0, rotationY: 0, rotationZ: 0, scale: 1, ease: 'power1.out' },
				{
					duration: 1,
					scale: 1,
					rotationX: 30,
					rotationY: -30,
					rotationZ: 15,
					z: 100,
					y: '100%',
					top: '100%',
					ease: 'power1.out',
				}
			)
			.fromTo(
				'.intro-kv-body',
				{ duration: 1, alpha: 0, y: '100%', ease: 'power1.out' },
				{ duration: 1, alpha: 1, y: '0%', ease: 'power1.out' },
				'-=0.3'
			)
			.to('.intro-kv-body', { duration: 1, alpha: 0, y: '-100%', ease: 'power1.out' })
			.to('.intro-kv-main', { duration: 1, alpha: 0, ease: 'power1.out' })
			.to('.youtube-bg', { duration: 1, autoAlpha: 1 }, '-=0.8')
			.to('.youtube-video', { duration: 1, autoAlpha: 1, y: '0%' }, '-=0.5')
			.to('.youtube-video', { duration: 1, autoAlpha: 0, y: '-100%' })
			.fromTo(
				'.tiktok',
				{ duration: 1, autoAlpha: 0, y: '100%' },
				{ duration: 1, autoAlpha: 1, y: '0%' },
				'-=0.8'
			)

		const controller = new ScrollMagic.Controller()

		new ScrollMagic.Scene({
			triggerElement: '#xuat-kixx-loi-rieng',
			triggerHook: 0,
			duration: '500%',
		})
			.setPin('#xuat-kixx-loi-rieng')
			.setTween(tween)
			//.addIndicators()
			.addTo(controller)

		controller.scrollTo(function (newpos) {
			gsap.to(window, { duration: 0.4, scrollTo: { y: newpos } })
		})

		$(window).scroll(function () {
			const scrollPos = $(document).scrollTop()
			const posRule = $('#the-le-chuong-trinh').position().top
			const headerH = $('.header').height()

			if (Math.round(posRule - headerH - 1) <= scrollPos) {
				$('.navbar-item').removeClass('is-active')
				$('[href="#the-le-chuong-trinh"]').parent().addClass('is-active')
			} else {
				$('.navbar-item').removeClass('is-active')
				$('[href="#xuat-kixx-loi-rieng"]').parent().addClass('is-active')
			}
		})
	}

	init()
}
