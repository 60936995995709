APP.header = function () {
	const { hash, pathname, origin } = window.location
	const hashKixx = localStorage.getItem('hash-kixx')

	function init() {
		if (hashKixx && pathname === '/') {
			setTimeout(() => {
				const posID = $(hashKixx).position().top
				const headerH = $('.header').height()
				const posY = posID - headerH

				$('.navbar-item').removeClass('is-active')
				$(`[href="${hashKixx}"]`).parent().addClass('is-active')

				if (hashKixx === '#xuat-kixx-loi-rieng') {
					APP.setScrollTopAnimation(0)
				} else {
					APP.setScrollTopAnimation(posY)
				}
				localStorage.removeItem('hash-kixx')
			}, 500)
		}

		$('.toggle').on('click', function () {
			$('.header').toggleClass('is-show')
		})

		$('.navbar-scroll').on('click', function (e) {
			const _this = $(this)
			const parent = _this.parent()
			const id = _this.attr('href')

			$('.header').removeClass('is-show')

			if (pathname === '/') {
				const posID = $(id).position().top
				const headerH = $('.header').height()
				const posY = posID - headerH

				$('.navbar-item').removeClass('is-active')
				parent.addClass('is-active')

				if (id === '#xuat-kixx-loi-rieng') {
					APP.setScrollTopAnimation(0)
				} else {
					APP.setScrollTopAnimation(posY)
				}
			} else {
				localStorage.setItem('hash-kixx', id)
				window.location.href = origin
			}
		})
	}

	init()
}
