APP.product = function () {
	const slickProduct = $('.slick-product')
	const btnProduct = $('.btn-product')
	const modalProduct = $('.modal-product')
	const modalClose = modalProduct.find('.modal-close')
	const cardProduct = modalProduct.find('.card-product')

	function init() {
		slickProduct.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 1000,
		})

		btnProduct.on('click', function () {
			const _this = $(this)
			const parent = _this.parents('.card-product')
			const productBody = parent.find('.card-product-modal')
			cardProduct.html('')
			console.log(productBody.clone())
			productBody.clone().appendTo(cardProduct)
			modalProduct.show()

			gsap.set(modalProduct, {
				display: 'block',
				y: 100,
				alpha: 0,
			})

			gsap.to(modalProduct, {
				duration: 0.5,
				y: 0,
				alpha: 1,
				delay: 0.15,
				ease: 'back.out',
			})
		})

		modalClose.on('click', function () {
			gsap.to(modalProduct, {
				duration: 0.5,
				y: 100,
				alpha: 0,
				delay: 0.15,
				ease: 'back.out',
				onComplete: function () {
					modalProduct.removeAttr('style')
				},
			})
		})

		$('.scrollbar-macosx').scrollbar()
	}

	init()
}
