APP.init = function () {
	APP.setProperty()
	APP.device()
	APP.header()
	APP.modal()
	APP.form()
	APP.home()
	APP.product()
	APP.survey()
}
