APP.validate = function (element, options) {
	const el = $(element)
	const buttonSubmit = el.find('[type="submit"]')
	const requiredFields = el.find('[data-required]')

	const settings = $.extend(
		{
			isAjax: false,
			isCaptcha: false,
			onComplete: function () {},
		},
		options
	)

	function init() {
		useMask()
		checkKeyCode()
		buttonSubmit.on('click', handleSubmit)
	}

	function handleSubmit(e) {
		e.preventDefault()
		let isFlag = true

		if (requiredFields.length > 0) {
			requiredFields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const type = input.attr('type')
				const error = field.find('.form-error')
				const messageWrong = error.data('error-wrong')
				const messageEmail = error.data('error-email')
				const messagePhone = error.data('error-phone')
				let value = input.val()

				field.addClass(CLASS._valid)

				if (value === '') {
					showError(error, field, messageWrong)
					isFlag = false
				} else {
					removeError(error, field)

					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								showError(error, field, messageEmail)
							}
							break
						case 'tel':
							if (!checkPhone(value)) {
								showError(error, field, messagePhone)
							}
							break
						case 'radio':
							if (!input.is(':checked')) {
								showError(error, field, messageWrong)
							}
							break
						case 'checkbox':
							if (!input.is(':checked')) {
								showError(error, field, messageWrong)
							}
							break
						default:
							break
					}
				}

				input.on('keydown', function (evt) {
					const obj = $(this)
					const type = obj.attr('type')
					let value = obj.val()

					if (obj.attr('name') !== 'phone') {
						if (value !== '' && type !== 'radio' && type !== 'checkbox') {
							removeError(error, field)
						} else {
							if (obj.is(':checked')) {
								removeError(error, field)
							}
						}
					}
				})

				input.on('change', function () {
					const obj = $(this)
					const type = obj.attr('type')
					let value = obj.val()

					if (obj.attr('name') !== 'phone') {
						if (value !== '' && type !== 'radio' && type !== 'checkbox') {
							removeError(error, field)
						} else {
							if (obj.is(':checked')) {
								removeError(error, field)
							}
						}
					}
				})
			})

			requiredFields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const type = input.attr('type')
				const value = input.val()

				if (value === '') {
					inputFocus(input, field)
					return false
				} else {
					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								inputFocus(input, field)
								return false
							}
							break
						case 'tel':
							if (!checkPhone(value)) {
								inputFocus(input, field)
								return false
							}
							break
							zq
						case 'radio':
							if (!input.is(':checked')) {
								inputFocus(input, field)
								return false
							}
							break
						case 'checkbox':
							if (!input.is(':checked')) {
								inputFocus(input, field)
								return false
							}
							break
						default:
							break
					}
				}
			})

			if (!requiredFields.hasClass(CLASS._error) && requiredFields.hasClass(CLASS._valid)) {
				let data = objectifyForm(el.serializeArray())
				settings.onComplete(data)
				if (isFlag && !settings.isAjax) {
					el.submit()
				}
			}
		} else {
			el.submit()
		}
	}

	function useMask() {
		requiredFields.each(function () {
			const field = $(this)
			const input = field.find('input, textarea, select')
			const type = input.attr('type')
			const name = input.attr('name')
			const error = field.find('.form-error')
			const messageWrong = error.data('error-wrong')
			const messageEmail = error.data('error-email')
			const messagePhone = error.data('error-phone')
			const messageMinLength = error.data('error-min-length')
			const messageCharSame = error.data('error-char-same')
			let value = input.val()

			field.addClass(CLASS._valid)

			input.on('focusout', function () {
				const _this = $(this)
				const name = _this.attr('name')
				value = input.val()

				console.log(name)

				if (value === '') {
					showError(error, field, messageWrong)
				} else {
					if (name !== 'identity_card') {
						removeError(error, field)
					}

					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								showError(error, field, messageEmail)
							} else {
								removeError(error, field)
							}
							break
						case 'tel':
							if (!checkPhone(value)) {
								showError(error, field, messagePhone)
							} else {
								removeError(error, field)
							}
							break
						case 'radio':
							if (!input.is(':checked')) {
								showError(error, field, messageWrong)
							} else {
								removeError(error, field)
							}
							break
						case 'checkbox':
							if (!input.is(':checked')) {
								showError(error, field, messageWrong)
							} else {
								removeError(error, field)
							}
							break
						default:
							break
					}

					if (name === 'fullname') {
						if (value.length > 1) {
							if (allCharactersSame(value)) {
								showError(error, field, messageCharSame)
							} else {
								removeError(error, field)
							}
						} else {
							showError(error, field, messageMinLength)
						}
					}

					field.removeClass(CLASS._valid)
				}

				if (!requiredFields.hasClass(CLASS._error) && !requiredFields.hasClass(CLASS._valid)) {
					const captchaResponse = grecaptcha.getResponse()
					if (captchaResponse.length === 0 && settings.isCaptcha) {
						buttonSubmit.attr('disabled', true)
					} else {
						buttonSubmit.removeAttr('disabled')
					}
				} else {
					buttonSubmit.attr('disabled', true)
				}
			})
		})

		$('[name="name"]').alphanum({
			allowSpace: true,
			allowUpper: true,
			allowNumeric: false,
			allowOtherCharSets: true,
		})

		$('[name="token"]').mask('AAAAAA')

		$('[name="phone_number"]').mask('0000000000', {
			onKeyPress: function (cep, event, currentField) {
				const parent = currentField.parents('.form-group')
				const formError = parent.find('.form-error')
				const textError = formError.data('error-phone')
				if (cep.length < 10) {
					parent.addClass(CLASS._error)
					formError.html(textError)
				} else {
					parent.removeClass(CLASS._error)
					formError.html('')
				}
			},
		})

		$('[name="identity_card"]').mask('000000000000', {
			onKeyPress: function (cep, event, currentField) {
				const parent = currentField.parents('.form-group')
				const formError = parent.find('.form-error')
				const textError = formError.data('error-identity')
				if (cep.length < 12) {
					parent.addClass(CLASS._error)
					formError.html(textError)
				} else {
					parent.removeClass(CLASS._error)
					formError.html('')
				}
			},
		})
	}

	function checkKeyCode() {
		if (requiredFields.length > 0) {
			requiredFields.each(function () {
				const field = $(this)
				const input = field.find('input, textarea, select')
				const type = input.attr('type')

				switch (type) {
					case 'tel':
						input.on('keydown', function (evt) {
							return evt.keyCode !== 69
						})
						break
					default:
						break
				}
			})
		}
	}

	function showError(error, field, message) {
		error.html(message)
		action(field)
	}

	function removeError(error, field) {
		error.html('')
		field.removeClass(CLASS._error).removeClass(CLASS._focus)
	}

	function inputFocus(input, field) {
		const inputFirst = input.first()
		inputFirst.focus()
		field.addClass(CLASS._focus)
	}

	function action(field) {
		field.addClass(CLASS._error)
		field.removeClass(CLASS._valid)
	}

	function checkEmail(email) {
		const reg =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return reg.test(String(email).toLowerCase())
	}

	function checkPhone(phone) {
		//const reg = /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/
		const reg = /(0)(3|5|7|8|9)+([0-9]{8})\b/
		return reg.test(phone)
	}

	function objectifyForm(formArray) {
		const returnArray = {}
		for (var i = 0; i < formArray.length; i++) {
			returnArray[formArray[i]['name']] = formArray[i]['value']
		}
		return returnArray
	}

	function allCharactersSame(s) {
		let n = s.length
		for (let i = 1; i < n; i++) if (s[i] != s[0]) return false
		return true
	}

	init()
}
