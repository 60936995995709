APP.survey = function () {
	const step = $('.step')
	const stepIntro = $('.step-intro')
	const btnSurvey = stepIntro.find('.btn-survey')

	const loader = $('.loader')

	const stepSurvey = $('.step-survey')
	const navSurvey = stepSurvey.find('.nav-survey')
	const sliderSurvey = stepSurvey.find('.slider-survey')

	const stepRegister = $('.step-register')
	const numberCorrect = step.find('.number-correct')
	const numberQuestion = step.find('.number-question')

	const stepMessage = $('.step-message')

	const stepOTP = $('.step-otp')
	const stepEmail = stepOTP.find('.step-email')

	const stepSuccess = $('.step-success')
	const stepFailure = $('.step-failure')
	const stepOutOfStock = $('.step-out-of-stock')
	const stepOutOfRewawd = $('.step-out-of-reward')
	const stepMaximun = $('.step-maximun')
	const stepExist = $('.step-exist')

	const [getSurvey, setSurvey] = useSurvey()

	let answer_data = []
	let countCorrect = 0

	async function init() {
		const surveryItems = await getData(`${apiUrl}/api/v1/question`)
		if (surveryItems) {
			setSurvey(surveryItems?.data)
			renderSurveyToDom(getSurvey())
		}

		btnSurvey.on('click', handleShowSurvey)

		$('.step-close').on('click', function () {
			gsap.to('.step', {
				duration: 0.4,
				autoAlpha: 0,
				ease: 'power1.out',
				onComplete: function () {
					gsap.set('.step', {
						display: 'none',
					})

					gsap.set(stepIntro, {
						display: 'block',
						autoAlpha: 0,
					})

					gsap.to(stepIntro, {
						duration: 0.4,
						autoAlpha: 1,
						ease: 'power1.out',
					})

					answer_data = []
					countCorrect = 0

					grecaptcha.reset()
					$('.form-register')[0].reset()
					renderSurveyToDom(getSurvey())
				},
			})
		})

		APP.validate('.form-register', {
			isAjax: true,
			isCaptcha: true,
			onComplete: function (res) {
				const dataSubmit = {
					...res,
					url_location_href: window.location.href,
					answer_data,
				}

				loader.show()

				fetch(`${apiUrl}/api/v1/player-answer/store`, {
					method: 'POST',
					headers: {
						'Content-type': 'application/json',
					},
					body: JSON.stringify(dataSubmit),
				})
					.then((response) => response.json())
					.then((res) => {
						const { message } = res

						loader.hide()

						switch (message) {
							case 'out-of-stock':
								stepAnimate(stepRegister, stepOutOfStock)
								break
							case 'out-of-reward':
								stepAnimate(stepRegister, stepOutOfRewawd)
								break
							case 'maximun-player-reward':
								stepAnimate(stepRegister, stepMaximun)
								break
							case 'no-play':
								stepAnimate(stepRegister, stepFailure)
								break
							case 'exist-user':
								stepAnimate(stepRegister, stepExist)
								break
							default:
								let str = dataSubmit?.email
								str = str.split('')
								let finalArr = []
								let len = str.indexOf('@')
								str.forEach((item, pos) => {
									pos >= 1 && pos <= len - 2 ? finalArr.push('*') : finalArr.push(str[pos])
								})
								stepEmail.html(finalArr.join(''))
								stepAnimate(stepRegister, stepOTP)
								break
						}
					})
					.catch((error) => {
						console.log(error)
					})
			},
		})

		APP.validate('.form-otp', {
			isAjax: true,
			onComplete: function (res) {
				const dataSubmit = {
					...res,
				}

				loader.show()

				fetch(`${apiUrl}/api/v1/player-answer/confirm-token`, {
					method: 'POST',
					headers: {
						'Content-type': 'application/json',
					},
					body: JSON.stringify(dataSubmit),
				})
					.then((response) => response.json())
					.then((res) => {
						const { message } = res
						const formOTP = $('.form-otp')
						const formError = formOTP.find('.form-error')

						loader.hide()

						switch (message) {
							case 'out-of-stock':
								stepAnimate(stepOTP, stepOutOfStock)
								break
							case 'out-of-reward':
								stepAnimate(stepOTP, stepOutOfRewawd)
								break
							case 'maximun-player-reward':
								stepAnimate(stepOTP, stepMaximun)
								break
							case 'exist-token-or-wrong-token':
								formError.html('Mã OTP bạn nhập không đúng')
								break
							case 'token-expired':
								formError.html('Mã OTP đã quá hạn sử dụng')
								break
							default:
								stepAnimate(stepOTP, stepSuccess)
								break
						}
					})
					.catch((error) => {
						console.log(error)
					})
			},
		})
	}

	function handleShowSurvey() {
		loader.show()
		fetch(`${apiUrl}/api/v1/stock/check-stock`, {
			method: 'GET',
			headers: {
				'Content-type': 'application/json',
			},
			//body: JSON.stringify(dataSubmit),
		})
			.then((response) => response.json())
			.then((res) => {
				loader.hide()
				if (res === 'out-of-stock' || res === 'out-of-reward-per-day') {
					stepAnimate(stepIntro, stepOutOfStock)
				} else {
					gsap.to(stepIntro, {
						duration: 0.4,
						autoAlpha: 0,
						ease: 'power1.out',
						onComplete: function () {
							gsap.set(stepIntro, {
								display: 'none',
							})

							gsap.set(stepSurvey, {
								display: 'block',
								autoAlpha: 0,
							})

							gsap.to(stepSurvey, {
								duration: 0.4,
								autoAlpha: 1,
								ease: 'power1.out',
							})
						},
					})
				}
			})
			.catch((error) => {
				loader.hide()
				console.log(error)
			})
	}

	function renderNavSurveyItem() {
		return `<li class="nav-survey-item">
			<i class="nav-survey-dot"></i>
		</li>`
	}

	function renderSurveyItem(item, index) {
		return `<div class="slider-survey-item">
			<h4 class="slider-survey-title">${item?.title}</h4>
			<ul class="answer-survey">
				${item?.question_options
					.map(
						(answer) =>
							`<li class="answer-survey-item"><span class="answer-survey-text" data-question-idx="${index}" data-answer-id="${answer?.id}">${answer?.title}</span></li>`
					)
					.join('')}
			</ul>
		</div>`
	}

	function renderSurveyToDom(surveyItems) {
		const navItems = surveyItems.map(() => renderNavSurveyItem())
		const slideItems = surveyItems.map((item, index) => renderSurveyItem(item, index))
		navSurvey.html(navItems)
		sliderSurvey.html(slideItems)

		const navSurveyItem = navSurvey.find('.nav-survey-item')
		const sliderSurveyItem = sliderSurvey.find('.slider-survey-item')
		const answerSurvey = sliderSurvey.find('.answer-survey')
		const answerSurveyText = answerSurvey.find('.answer-survey-text')

		answerSurveyText.on('click', function () {
			const _this = $(this)
			const questionIdx = _this.data('question-idx')
			const answerId = _this.data('answer-id')
			const answerItem = surveyItems[questionIdx]?.question_options.find(
				(answer) => answer?.id === answerId
			)
			const { slug: slugQuestion } = surveyItems[questionIdx]
			const { in_correct, slug: slugAnswer } = answerItem
			const questionAnswer = { question: slugQuestion, answer: slugAnswer }

			answer_data.push(questionAnswer)

			const sliderSurveyItemComplete = sliderSurveyItem.eq(questionIdx)
			const sliderSurveyItemCurrent = sliderSurveyItem.eq(questionIdx + 1)
			const navSurveyItemComplete = navSurveyItem.eq(questionIdx)
			const navSurveyItemCurrent = navSurveyItem.eq(questionIdx + 1)

			_this.addClass('is-choose')
			sliderSurveyItemComplete.addClass('is-complete')

			if (in_correct) {
				countCorrect = countCorrect + 1
			}

			if (questionIdx < surveyItems.length - 1) {
				gsap.to(sliderSurveyItemComplete, {
					duration: 0.5,
					autoAlpha: 0,
					x: '-100%',
					delay: 0.5,
					ease: 'power1.in',
					onComplete: function () {
						navSurveyItemComplete.addClass('is-complete')
						navSurveyItemCurrent.addClass('is-current')

						gsap.set(sliderSurveyItemComplete, {
							display: 'none',
						})

						gsap.set(sliderSurveyItemCurrent, {
							display: 'block',
							autoAlpha: 0,
							x: '100%',
						})

						gsap.to(sliderSurveyItemCurrent, {
							duration: 0.5,
							autoAlpha: 1,
							delay: 0.15,
							x: 0,
							ease: 'power1.out',
						})
					},
				})
			} else {
				numberCorrect.text(countCorrect)
				numberQuestion.text(surveyItems.length)

				if (countCorrect >= surveyItems.length - 1) {
					stepAnimate(stepSurvey, stepRegister)
				} else {
					stepAnimate(stepSurvey, stepMessage)
				}
			}
		})
	}

	function stepAnimate(stepHide, stepShow) {
		gsap.to(stepHide, {
			duration: 0.4,
			autoAlpha: 0,
			ease: 'power1.out',
			onComplete: function () {
				gsap.set(stepHide, {
					display: 'none',
				})

				gsap.set(stepShow, {
					display: 'block',
					autoAlpha: 0,
				})

				gsap.to(stepShow, {
					duration: 0.4,
					autoAlpha: 1,
					ease: 'power1.out',
				})
			},
		})
	}

	function useSurvey() {
		let _survey = null

		function getSurvey() {
			return _survey
		}

		function setSurvey(data) {
			_survey = [...data]
		}

		return [getSurvey, setSurvey]
	}

	async function getData(url) {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
			},
		})

		const res = await response.json()

		return res
	}

	init()
}
