const APP = {}

const DOCUMENT = $(document)
const WINDOW = $(window)
const HTML = $('html')
const BODY = $('body')
const MAIN = $('.main')

const CLASS = {
	_desktop: 'is-desktop',
	_tablet: 'is-tablet',
	_mobile: 'is-mobile',
	_portrait: 'is-portrait',
	_landscape: 'is-landscape',
	_active: 'is-active',
	_current: 'is-current',
	_error: 'is-error',
	_valid: 'is-valid',
	_focus: 'is-focus',
	_noscroll: 'is-noscroll',
	_filled: 'is-filled',
}

const apiUrl = 'https://admin-prod.xuatkixx.com'

const lenis = new Lenis()

function raf(time) {
	lenis.raf(time)
	requestAnimationFrame(raf)
}

requestAnimationFrame(raf)

function captchaCallback() {
	const formRegister = $('.form-register')
	const requiredFields = formRegister.find('[data-required]')
	const btnRegsiter = formRegister.find('.btn-register')
	if (!requiredFields.hasClass(CLASS._error) && !requiredFields.hasClass(CLASS._valid)) {
		btnRegsiter.removeAttr('disabled')
	} else {
		btnRegsiter.attr('disabled', true)
	}
}

function captchaExpired() {
	const formRegister = $('.form-register')
	const btnRegsiter = formRegister.find('.btn-register')
	btnRegsiter.attr('disabled', true)
}
