APP.form = function () {
	const formControl = $('.form-control')

	const init = function () {
		placeholderLabel()
	}

	const placeholderLabel = function () {
		formControl.each(function () {
			const obj = $(this)
			const field = obj.closest('.form-group')
			const type = obj.attr('type')
			const value = obj.val()

			if (value !== '' && type !== 'checkbox' && type !== 'radio') {
				field.addClass(CLASS._filled)
			} else {
				if (obj.is(':checked')) {
					field.addClass(CLASS._filled)
				} else {
					field.removeClass(CLASS._filled)
				}
			}
		})

		formControl.on('focus', function () {
			const obj = $(this)
			const field = obj.closest('.form-group')
			field.addClass(CLASS._filled)
		})

		formControl.on('blur input', function () {
			const obj = $(this)
			const field = obj.closest('.form-group')
			const type = obj.attr('type')
			const value = obj.val()

			if (value !== '' && type !== 'checkbox' && type !== 'radio') {
				field.addClass(CLASS._filled)
			} else {
				if (obj.is(':checked')) {
					field.addClass(CLASS._filled)
				} else {
					field.removeClass(CLASS._filled)
				}
			}
		})

		$('select').on('change', function () {
			var field = $(this).closest('.form-group')
			if (this.value) {
				field.addClass(CLASS._filled)
			} else {
				field.removeClass(CLASS._filled)
			}
		})
	}

	init()
}
